import { h, reactive, ref } from 'vue';
import { NButton, NImage, NTag, useDialog } from 'naive-ui';
import AppIcon from '@/components/AppIcon.vue';
import state from '@/state';

export default {
  name: 'PastJobDashboard',
  setup() {
    const paginationReactive = reactive({
      page: 1,
      pageSize: 24,
      showSizePicker: true,
      pageSizes: [12, 24, 36, 48],
      onChange: (page) => {
        paginationReactive.page = page
      },
      onUpdatePageSize: (pageSize) => {
        paginationReactive.pageSize = pageSize
        paginationReactive.page = 1
      }
    });

    const dialog = useDialog();

    return {
      pagination: paginationReactive,
      dialog,
    }
  },
  data: () => {
    return {
      printerName: null,
      printerType: null,
      jobs: ref([]),
      jobsData: ref([]),
      totalJobCount: ref(0),
      columns: [],
      showModal: ref(false),
      modalContent: ref(''),
      showClear: ref(false),
      searchValue: ref(null),
      dateDelay: null,
    }
  },
  computed: {
    headerText() {
      return 'Past Print Jobs - ' + this.printerName;
    }
  },
  methods: {
    capitalize(string) {
      let capitalized = []

      string.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      });

      return capitalized.join(' ');
    },
    createColumns() {
      const self = this;
      return [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
        },
        {
          title: 'Date/Time',
          key: 'date',
          width: 110,
        },
        {
          title: 'Status',
          key: 'status',
          align: 'center',
          render(job) {
            let tagType = 'success';
            if(job.status === 'Cancelled') {
              tagType = "error";
            }

            return h(NTag, { type: tagType, round: true }, job.status);
          },
        },
        {
          title: 'Details',
          key: 'details',
          render(job) {
            if (self.printerType === 'multiple') {
              return [
                h('strong', { class: 'details-title' }, 'Multiple Products'),
                h('div', { class: 'details-content' }, ''),
                h(NButton, 
                  {  
                    size: 'tiny',
                    color: '#dcdfe6',
                    textColor: '#606266',
                    onClick: () => self.handleMultiDetailsModal(job),
                  }, 
                  { 
                    default: () => [
                      h(AppIcon, { type: 'EyeRegular' }),
                      h('span', 'View all'),
                    ]
                  },
                ),
              ];
            }

            return [
              h('strong', { class: 'details-title' }, 'Product Name'),
              h('div', { class: 'details-content' }, job.product_name),
              h('strong', { class: 'details-title' }, 'Product SKU'),
              h('div', { class: 'details-content' }, job.product_sku),
              h(NButton, 
                {  
                  size: 'tiny',
                  color: '#dcdfe6',
                  textColor: '#606266',
                  onClick: () => self.handleDetailsModal(job),
                }, 
                { 
                  default: () => [
                    h(AppIcon, { type: 'EyeRegular' }),
                    h('span', 'View more'),
                  ]
                },
              ),
            ];
          },
        },
        {
          title: 'Barcode',
          key: 'barcode',
          width: 140,
          render(job) {
            if (self.printerType === 'multiple') {
              return [
                h('div', { class: 'barcode-title' }, 'Multiple Barcodes'),
                h('div', { class: 'barcode-text' }, job.barcodes[0].value),
                h(NButton, 
                  {  
                    size: 'tiny',
                    color: '#dcdfe6',
                    textColor: '#606266',
                    onClick: () => self.handleBarcodesModal(job),
                  }, 
                  { 
                    default: () => [
                      h(AppIcon, { type: 'EyeRegular' }),
                      h('span', 'View barcodes'),
                    ]
                  },
                ),
              ];
            }

            return [
              h('div', { class: 'barcode-text' }, job.barcode),
              h(NImage, { src: job.barcode_img, width: 120, lazy: true }),
            ];
          },
        },
        {
          title: 'Qty',
          key: 'qty',
          align: 'center',
        },
        {
          title: 'Product',
          key: 'product',
          align: 'center',
          render(job) {
            return  h(NImage, { src: job.product, width: 80, lazy: true });
          },
        },
        {
          title: 'Design',
          key: 'design',
          align: 'center',
          render(job) {
            if (self.printerType === 'multiple') {
              return [
                h('div', { class: 'design-title' }, 'Multiple Designs'),
                h('div', { class: 'barcode-text' }, ''),
                h(NButton, 
                  {  
                    size: 'tiny',
                    color: '#dcdfe6',
                    textColor: '#606266',
                    onClick: () => self.handleDesignsModal(job),
                  }, 
                  { 
                    default: () => [
                      h(AppIcon, { type: 'EyeRegular' }),
                      h('span', 'View designs'),
                    ]
                  },
                ),
              ];
            }
            
            return  h(NImage, { src: job.design, height: 100, lazy: true });
          },
        },
        {
          title: 'Job Sheet',
          align: 'center',
          key: 'job_sheet',
          render(job) {
            if(typeof job.job_sheet !== "undefined"
              && job.job_sheet !== null
              && job.job_sheet !== ''
            ) {
              return h(NButton, 
                {
                  tag: "a",
                  href: job.job_sheet,
                  target: "_blank",
                  size: 'tiny',
                  color: '#dcdfe6',
                  textColor: '#606266',
                },
                {
                  default: () => [
                    h(AppIcon, { type: 'EyeRegular' }),
                    h('span', 'Job Sheet'),
                  ]
                },
              );
            } else {
              return '';
            }
          }
        },
        {
          title: 'Delete',
          key: 'delete',
          align: 'center',
          render(job) {
            let showDelete = false;
            const dateParts = job.date.split(' ');
            const today = new Date();
            const past = new Date(dateParts[0]);
            const day = 1000 * 60 * 60 * 24;

            // This date calculation is not precise but fine for this use case self.dateDelay
            const diff = Math.floor(today.getTime() - past.getTime());
            const days = Math.floor(diff/day);
            const months = Math.floor(days/31);

            if(self.dateDelay === '7d' && days > 6) {
              showDelete = true;
            }
            if(self.dateDelay === '14d' && days > 13) {
              showDelete = true;
            }
            if(self.dateDelay === '1m' && months >= 1) {
              showDelete = true;
            }
            if(self.dateDelay === '2m' && months >= 2) {
              showDelete = true;
            }
            if(self.dateDelay === '3m' && months >= 3) {
              showDelete = true;
            }

            if(showDelete) {
              return h(AppIcon, { type: 'TrashAlt', color: '#cc0000', style: 'cursor:pointer;', onClick: () => self.deletePastJob(job) });
            } else {
              return null;
            }
          },
        },
      ];
    },
    clearAllFilters() {
      this.showClear = false;
      this.searchValue = null;
      this.processRecords();
    },
    performSearch() {
      if (this.searchValue == '' || this.searchValue == null) {
        const message = 'Please enter a search term!';
        window.$message.warning(message, {
          closable: true,
          duration: 6000,
          keepAliveOnHover: true,
        });
        return;
      }

      this.showClear = true;
      this.processRecords();
    },
    async handleDetailsModal(job) {
      const partnerName = await state.getPartnerName();

      let attributes = '<br>';
      if(job.attributes !== null) {
        for (const [key, value] of Object.entries(job.attributes)) {
          const title = this.capitalize(key.replaceAll("_", " "));
          attributes += `<strong>${title}:</strong> ${value}<br>`;
        }
      }

      let metaData = '<br>';
      if(job.meta_data !== null) {
        for (const [key, value] of Object.entries(job.meta_data)) {
          if(key == 'styles' 
            || key == 'day_style' 
            || key == 'branding_logo') 
          {
            continue;
          }

          if(key == 'attributes') {
            let jobAttributesArray = [];

            value.forEach((item) => jobAttributesArray.push(item.code));

            let jobAttributes = jobAttributesArray.join('|');

            metaData += `<strong>Attribute:</strong> ${jobAttributes}<br>`; 
          } else {
            const title = this.capitalize(key.replaceAll("_", " "));
            metaData += `<strong>${title}:</strong> ${value}<br>`; 
          }
        }
      }

      const content = `<div class="modal-details">
      <div class="details-left">
      <strong class="details-title">Product Name</strong>
      <div class="details-content">${job.product_name}</div>
      <strong class="details-title">Product SKU</strong>
      <div class="details-content">${job.product_sku}</div>
      <strong class="details-title">External Job ID</strong>
      <div class="details-content">${job.external_id}</div>
      </div>
      <div class="details-right">
      <strong class="details-title">Product Attributes</strong>
      <div class="details-content">
      ${attributes}
      </div>
      </div>
      </div>
      <div class="details-partner">
      <hr>
      <strong class="details-title">${partnerName} Details</strong>
      <div class="details-content">
      ${metaData}
      </div>
      </div>`;

      this.modalContent = content;
      this.showModal = true;
    },
    async handleMultiDetailsModal(job) {
      const partnerName = await state.getPartnerName();
      let content = '';
      
      for (let i in job.multi_data) {
        let productNumber = parseInt(i) + 1;
        let productName = job.multi_data[i].product_name;
        let productSku = job.multi_data[i].sku;
        let productImage = job.multi_data[i].product_with_design_image_path;
        let barcodeImage = job.barcodes[i].barcode_image_file_path;
        let barcodeValue = job.barcodes[i].value;

        let attributes = '<br>';
        if(job.multi_data[i].attributes !== null) {
          for (const [key, value] of Object.entries(job.multi_data[i].attributes)) {
            const title = this.capitalize(key.replaceAll("_", " "));
            attributes += `<strong>${title}:</strong> ${value}<br>`;
          }
        }

        let metaData = '<br>';
        if(job.multi_data[i].meta_data !== null) {
          for (const [key, value] of Object.entries(job.multi_data[i].meta_data)) {
            if(key == 'styles' 
              || key == 'day_style' 
              || key == 'branding_logo') 
            {
              continue;
            }

            if(key == 'attributes') {
                let jobAttributesArray = [];

                value.forEach((item) => jobAttributesArray.push(item.code));

                let jobAttributes = jobAttributesArray.join('|');

                metaData += `<strong>Attribute:</strong> ${jobAttributes}<br>`; 
            } else {
              const title = this.capitalize(key.replaceAll("_", " "));
              metaData += `<strong>${title}:</strong> ${value}<br>`; 
            }
          }
        }

        content += `<div class="modal-details">
        <div class="details-left">
        <div class="multi-product-number">${productNumber}</div>
        <img src="${productImage}" alt="${productName}" class="multi-product-image">
        </div>
        <div class="details-right">
        <strong class="details-title">Product Name</strong>
        <div class="details-content">${productName}</div>
        <strong class="details-title">Product SKU</strong>
        <div class="details-content">${productSku}</div>
        <strong class="details-title">Barcode</strong>
        <div class="details-content">
        ${barcodeValue}<br>
        <img src="${barcodeImage}" alt="${barcodeValue}" class="multi-product-barcode-image">
        </div>
        <strong class="details-title">Product Attributes</strong>
        <div class="details-content">
        ${attributes}
        </div>
        </div>
        </div>
        <div class="details-partner">
        <strong class="details-title">${partnerName} Details</strong>
        <div class="details-content">
        ${metaData}
        </div>
        </div>
        <hr>`;
      }

      this.modalContent = content;
      this.showModal = true;
    },
    async handleBarcodesModal(job) {
      let content = '';

      for (let i in job.barcodes) {
        let productNumber = parseInt(i) + 1;
        let barcodeImage = job.barcodes[i].barcode_image_file_path;
        let barcodeValue = job.barcodes[i].value;
        
        content += `<div class="modal-details modal-details-barcode">
        <div class="multi-product-number multi-product-number-barcode">${productNumber}</div>
        ${barcodeValue}<br>
        <img src="${barcodeImage}" alt="${barcodeValue}" class="multi-product-barcode-image">
        </div>
        <hr>`;
      }

      this.modalContent = content;
      this.showModal = true;
    },
    async handleDesignsModal(job) {
      let content = '';

      for (let i in job.multi_data) {
        let productNumber = parseInt(i) + 1;
        let designImage = job.multi_data[i].production_partner_job_design_image_path;
        
        content += `<div class="modal-details modal-details-design">
        <div class="multi-product-number">${productNumber}</div>
        <img src="${designImage}" alt="${productNumber}" class="multi-product-design-image">
        </div>`;
      }

      this.modalContent = content;
      this.showModal = true;
    },
    async deletePastJob(job) {
      // Show confirm dialog
      this.dialog.error({
        title: 'Delete past job?',
        content: 'Are you sure you want to delete this past job, this action can not be reversed?',
        positiveText: 'Yes',
        negativeText: 'No',
        onPositiveClick: () => {
          this.processDeletePastJob(job)
        },
      });
    },
    async processDeletePastJob(job) {
      const locale = await state.getLocale();

      const nameType = await state.getFolder();
      let folder = job.id;

      if (nameType === 'barcode') {
        folder = job.barcode;
      }

      try {
        // Remove job artwork folder
        await window.$handle.removeEntry(folder, { recursive: true });

        // Remove record from db
        await state.deletePrintPastJob(`job_${job.id}`);

        // Update history
        const history = {
          type: 'Past Job Deleted',
          job: job.id,
          message: `The past job record and files have been successfully deleted for job with ID: ${job.id}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        const message = 'The past job has been successfully deleted';
        window.$message.success(message, {
          closable: true,
          duration: 6000,
        });

        this.processRecords();
      } catch (error) {
        // Just remove the record
        await state.deletePrintPastJob(`job_${job.id}`);

        // Update history
        const history = {
          type: 'Past Job Deleted with errors',
          job: job.id,
          message: `The past job record has been successfully deleted for job with ID: ${job.id} but bit was unable to delete the job files, Folder Name: ${folder}. Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        const message = `The past job has been deleted but was unable to delete the job files, they may have already been deleted or not created. The folder name is: ${folder}`;
        window.$message.warning(message, {
          closable: true,
          duration: 10000,
        });

        this.processRecords();
      }
    },
    async processRecords() {
      this.jobsData = await state.getPrintPastJobs();
      const self = this;
      let rows = [];
      let totalJobs = 0;

      this.jobsData.forEach( function(job) {
        totalJobs += 1;

        // Process search
        if (self.searchValue !== null) {
          let add = false;
          const lowerCaseSearch = String(self.searchValue).toLowerCase();

          if (self.searchValue == job.production_partner_job_id) {
            add = true;
          }

          if (self.printerType === 'multiple') {
            // Check barcodes
            if (job.barcodes !== null) {
              for (let n in job.barcodes) {
                const barcodeLowerCase = String(job.barcodes[n].value).toLowerCase();

                if (barcodeLowerCase.includes(lowerCaseSearch)) {
                  add = true;
                }
              }
            }

            // Check multi-data
            for (let i in job.multi_data) {
              const productName = job.multi_data[i].product_name.toLowerCase();
              const productSku = job.multi_data[i].sku.toLowerCase();
              if (productName.includes(lowerCaseSearch)
                || productSku.includes(lowerCaseSearch)
              ) {
                add = true;
              }

              // Loop and check metadata
              if (job.multi_data[i].meta_data !== null) {
                for (const [key, value] of Object.entries(job.multi_data[i].meta_data)) {
                  if (key === 'attributes') {
                    let attributeArray = [];

                    for (let n in value) {
                      attributeArray.push(String(value[n].code));
                    }

                    const attributeString = attributeArray.join("").toLowerCase();
                    const attributeStringWithPipe = attributeArray.join("|").toLowerCase();

                    if (attributeString.includes(lowerCaseSearch) 
                      || attributeStringWithPipe.includes(lowerCaseSearch)
                    ) {
                      add = true;
                    }
                  } else {
                    const valueLowerCase = String(value).toLowerCase();
                    if (valueLowerCase.includes(lowerCaseSearch) ) {
                      add = true;
                    } 
                  }
                }
              }
            }
          } else {
            const barcodeLowerCase = String(job.barcode_value).toLowerCase();
            const skuLowerCase = String(job.sku).toLowerCase();
            const externalIdLowerCase = String(job.external_id).toLowerCase();
            const lowerCaseName = job.product_name.toLowerCase();
            
            if (barcodeLowerCase.includes(lowerCaseSearch) 
              || skuLowerCase.includes(lowerCaseSearch)
              || externalIdLowerCase.includes(lowerCaseSearch)
              || lowerCaseName.includes(lowerCaseSearch)
            ) {
              add = true;
            }

            // Loop and check metadata
            if (job.meta_data !== null) {
              for (const [key, value] of Object.entries(job.meta_data)) {
                if (key === 'attributes') {
                  let attributeArray = [];

                  for (let i in value) {
                    attributeArray.push(String(value[i].code));
                  }

                  const attributeString = attributeArray.join("").toLowerCase();
                  const attributeStringWithPipe = attributeArray.join("|").toLowerCase();

                  if (attributeString.includes(lowerCaseSearch) 
                    || attributeStringWithPipe.includes(lowerCaseSearch)
                  ) {
                    add = true;
                  }
                } else {
                  const valueLowerCase = String(value).toLowerCase();
                  if (valueLowerCase.includes(lowerCaseSearch) ) {
                    add = true;
                  } 
                }
              }
            }
          }

          if (!add) {
            return;
          }
        }

        rows.push({
          id: job.production_partner_job_id,
          date: job.date.replace(',', ''),
          barcode: job.barcode_value,
          barcode_img: job.barcode_image_path,
          barcodes: job.barcodes,
          external_id: job.external_id,
          qty: job.quantity,
          product: job.product_with_design_image_path,
          design: job.production_partner_job_design_image_path,
          product_name: job.product_name,
          product_sku: job.sku,
          status: job.status,
          job_sheet: job.job_sheet,
          messages: job.messages,
          attributes: job.attributes,
          meta_data: job.meta_data,
          has_artwork: job.has_artwork,
          multi_data: job.multi_data,
          actions: null,
        });
      });

      // Update rows
      this.jobs = rows.reverse();

      this.totalJobCount = totalJobs;
    },
  },
  async beforeMount() {
    this.columns = await this.createColumns();
    this.printerType = await state.getPrinterType();
    this.printerName = await state.getPrinterName();
    this.dateDelay = await state.getDeleteDelay();
  },
  async mounted() {
    this.processRecords();
  },
}