import { ref } from 'vue';
import state from '@/state';

export default {
  name: 'AppHeader',
  data: () => {
    return {
      token: null,
      sessionToken: null,
      connected: false,
      reconnectInterval: null,
      newJobCount: ref(0),
      totalJobCount: ref(0),
      failedConnect: false,
      disconnected: false,
    }
  },
  methods: {
    showError(message, duration) {
      window.$message.error(message, {
        closable: true,
        duration: duration,
        keepAliveOnHover: true,
      });
    },
    showWarning(message, duration) {
      window.$message.warning(message, {
        closable: true,
        duration: duration,
        keepAliveOnHover: true,
      });
    },
    showSuccess(message, duration) {
      window.$message.success(message, {
        closable: true,
        duration: duration,
        keepAliveOnHover: true,
      });
    },
    connectToWsServer() {
      return new Promise((resolve, reject) => {
        window.$websocket = new WebSocket(this.wsAddress);

        window.$websocket.onopen = () => resolve(window.$websocket);

        window.$websocket.onerror = () => reject(new Error("Unable to connect to web socket server!")); 
      });
    },
    reconnect() {
        window.$websocket.close();
        window.$websocket = null;
        this.checkWebsocketConnection();
    },
    async checkFilePermissions() {
      const handle = await state.getDirectoryHandle();
      const options = { mode: 'readwrite' }

      if (typeof handle !== "undefined" && handle !== null) {
        // Check if permission was already granted. If so, return true.
        if ((await handle.queryPermission(options)) === 'granted') {
          window.$handle = handle;
          return true;
        }

        // Request permission. If the user grants permission, return true.
        if ((await handle.requestPermission(options)) === 'granted') {
          window.$handle = handle;
          return true;
        }

        // The user didn't grant permission, so return false.
        return false;
      }
    },
    showDownloadErrorNotice(id) {
      this.showError(
        `The job with ID: ${id} failed to download the job files.`, 
        8000
      );
    },
    async downloadJobFiles(jobDetails) {
      let reDownload = false;
      if (typeof jobDetails === "undefined") {
        if (typeof window.$newJobsHeader.downloadId === "undefined" || window.$newJobsHeader.downloadId === 0) {
          return;
        }
        reDownload = true;
        jobDetails = await state.getPrintJob(`job_${window.$newJobsHeader.downloadId}`);
      }
      
      await this.checkFilePermissions();

      const id = jobDetails.production_partner_job_id;
      const external_id = jobDetails.external_id;
      const artworkUrl = jobDetails.production_partner_job_artwork_image_path;
      const jobsheetUrl = jobDetails.job_sheet;
      const productUrl = jobDetails.product_with_design_image_path;
      const printerType = await state.getPrinterType();
      const nameType = await state.getFolder();
      const locale = await state.getLocale();
      let barcode = jobDetails.barcode_value;
      let barcodeUrl = jobDetails.barcode_image_path;
      let folder = id;
      let jobDirectoryHandle;

      // Set folder name
      if (nameType === 'barcode') {
        folder = barcode;
      }
      if (nameType === 'external_id') {
        folder = external_id;
      }

      // Check for multiple printer type
      if (printerType === 'multiple') {
        barcode = jobDetails.barcodes[0].value;
        barcodeUrl = jobDetails.barcodes[0].barcode_image_file_path;
        
        if (nameType === 'barcode') {
          folder = barcode;
        }
      }

      // Set job directory handle
      try {
        jobDirectoryHandle = await window.$handle.getDirectoryHandle(folder, {
          create: true,
        });
      } catch (error) {
        const history = {
          type: 'Job Files Failed to download',
          job: id,
          message: `The job files failed to be downloaded for job with ID: ${id}, unable to open directory handle! Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        this.showDownloadErrorNotice(id);

        return;
      }
      
      // Download artwork
      try {
        const artworkExtension = artworkUrl.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
        const artworkFileHandle = await jobDirectoryHandle.getFileHandle(`${folder}_artwork.${artworkExtension}`, { create: true });
        const writableArtwork = await artworkFileHandle.createWritable();
        const responseArtwork = await fetch(artworkUrl);
        await responseArtwork.body.pipeTo(writableArtwork);
      } catch (error) {
        const history = {
          type: 'Job Files Failed to download',
          job: id,
          message: `The job files failed to be downloaded for job with ID: ${id}, unable to download the artwork file! Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        this.showDownloadErrorNotice(id);

        return;
      }
      
      // Download Job Sheet
      try {
        if (jobsheetUrl !== null && jobsheetUrl !== '') {
          const jobsheetExtension = jobsheetUrl.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
          const jobsheetFileHandle = await jobDirectoryHandle.getFileHandle(`${folder}_jobsheet.${jobsheetExtension}`, { create: true });
          const writableJobsheet = await jobsheetFileHandle.createWritable();
          const responseJobsheet = await fetch(jobsheetUrl);
          await responseJobsheet.body.pipeTo(writableJobsheet);
        }
      } catch (error) {
        const history = {
          type: 'Job Files Failed to download',
          job: id,
          message: `The job files failed to be downloaded for job with ID: ${id}, unable to download the job sheet! Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        this.showDownloadErrorNotice(id);

        return;
      }
      
      // Download Barcode if not multiple
      if (printerType !== 'multiple') {
        try {
          const barcodeExtension = barcodeUrl.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
          const barcodeFileHandle = await jobDirectoryHandle.getFileHandle(`${folder}_barcode.${barcodeExtension}`, { create: true });
          const writableBarcode = await barcodeFileHandle.createWritable();
          const responseBarcode = await fetch(barcodeUrl);
          await responseBarcode.body.pipeTo(writableBarcode);
        } catch (error) {
          const history = {
            type: 'Job Files Failed to download',
            job: id,
            message: `The job files failed to be downloaded for job with ID: ${id}, unable to download the barcode file(s)! Error Message: ${error}`,
            date: new Date().toLocaleString(locale)
          };
          state.addHistory(history);
        }
      }
      
      // Download Product Image(s)
      try {
        if (printerType === 'multiple') {
          for (let i in jobDetails.multi_data) {
            let productImageUrl = jobDetails.multi_data[i].product_with_design_image_path;
            let productImageNumber = parseInt(i) + 1;
            let productExtension = productImageUrl.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
            let productFileHandle = await jobDirectoryHandle.getFileHandle(`${folder}_product_${productImageNumber}.${productExtension}`, { create: true });
            let writableProduct = await productFileHandle.createWritable();
            let responseProduct = await fetch(productImageUrl);
            await responseProduct.body.pipeTo(writableProduct);
          }
        } else {
          const productExtension = productUrl.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
          const productFileHandle = await jobDirectoryHandle.getFileHandle(`${folder}_product.${productExtension}`, { create: true });
          const writableProduct = await productFileHandle.createWritable();
          const responseProduct = await fetch(productUrl);
          await responseProduct.body.pipeTo(writableProduct);
        }
      } catch (error) {
        const history = {
          type: 'Job Product Images Failed to download',
          job: id,
          message: `The job files failed to be downloaded for job with ID: ${id}, unable to download the product image file(s)! Error Message: ${error}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      }
      
      // Update job details
      jobDetails.has_artwork = true;
      jobDetails.status = 'Ready';
      await state.addUpdatePrintJob(`job_${id}`, jobDetails);

      // Update history
      if (!reDownload) {
        const history = {
          type: 'Job Files Downloaded',
          job: id,
          message: `The job files (artwork, barcode etc...) have been downloaded for job with ID: ${id}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
      } else {
        const history = {
          type: 'Job Files Redownloaded',
          job: id,
          message: `The job files (artwork, barcode etc...) have been redownloaded for job with ID: ${id}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        window.$newJobsHeader.downloadId = 0;
      }

      this.showSuccess(
        `The job with ID: ${id} has successfully downloaded the job files.`, 
        6000
      );

      window.$newJobs.updated = true;
    },
    async countJobs() {
        const jobs = await state.getPrintJobs();
        let count = 0;
        let total = 0;

        jobs.forEach( await function(job) { 
          total += 1;
          if (job.status === 'New') {
            count += 1;
          }
        });

        window.$newJobs.count = window.$newJobsHeader.count = this.newJobCount = count;
        window.$newJobs.total = this.totalJobCount = total;
    },
    async onClose(event) {
        this.connected = false;
        
        state.setNotConnected();

        if (event.code === 1000 && event.wasClean === true) {
            this.reconnect();
        } else {
            if (this.failedConnect === false && this.disconnected === false) {
              this.disconnected = true;
              this.showError(`Disconnected from the Merchr Printer Web Socket Server, the app will try to reconnect every 60 seconds! Error Code: ${event.code}`, 300000);
            }
        }

        this.reconnectInterval = setInterval( () => {
           this.reconnect();
        }, 60000);

        const locale = await state.getLocale();
        const history = {
          type: 'Disconnected',
          job: 0,
          message: `The Printer App has been disconnected from the server, retry will commence in 60 seconds. Error Code: ${event.code}`,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);
    },
    async onMessage(event) {
      const data = JSON.parse(event.data);

      if (data.type === 'new_print_job') {
        // Process and save new job
        let historyTitle = 'New Job';
        let updated = false;
        let status = 'New';
        let accepted = false;
        let hasArtwork = false;
        let job = data.data;
        let productName = job.product_name;
        let barcode = job.barcode_value;
        const id = job.production_partner_job_id;
        const locale = await state.getLocale();
        const printerType = await state.getPrinterType();

        // Check if the job exists
        const addedJob = await state.getPrintJob(`job_${id}`);

        if (typeof addedJob !== "undefined" && addedJob !== null) {
          updated = true;
          status = 'Updated';
          accepted = addedJob.accepted;
          hasArtwork = addedJob.has_artwork;
          historyTitle = 'Job Updated';
          
          if (addedJob.accepted === false) {
            status = 'New';
          }
        }

        // Add date, status, server updated
        job.date = new Date().toLocaleString(locale);
        job.status = status;
        job.accepted = accepted;
        job.server_updated = updated;
        job.has_artwork = hasArtwork;
        job.messages = [];

        // Save job
        await state.addUpdatePrintJob(`job_${id}`, job);

        // Update job count
        window.$newJobs.new = true;
        window.$newJobs.count += 1;
        window.$newJobs.total += 1;
        this.totalJobCount = window.$newJobs.total;
        this.newJobCount = window.$newJobs.count;

        // Update history
        if (printerType === 'multiple') {
          productName = barcode = '(Multiple)';
        }
        const message = `${historyTitle} for product ${productName} with ID: ${id} and Barcode: ${barcode} has been saved.`;
        const history = {
          type: historyTitle,
          job: id,
          message: message,
          date: job.date
        };
        state.addHistory(history);

        this.showSuccess(message, 30000);

        this.countJobs();

        return;
      }
      if (data.type === 'cancel_print_job') {
        const id = data.data.production_partner_job_id;
        const key = `job_${id}`;
        const record = await state.getPrintJob(key);

        const barcode = record.barcode_value;
        const productName = record.product_name;

        await state.deletePrintJob(key);

        // Save to past jobs
        record.status = 'Cancelled';
        await state.addUpdatePrintPastJob(key, record);

        window.$newJobs.updated = true;

        // Update history
        const locale = await state.getLocale();
        const message = `Job Cancelled! Job for product ${productName} with ID: ${id} and Barcode: ${barcode} was cancelled and has been deleted from your job list.`;
        const history = {
          type: 'Cancelled Job',
          job: id,
          message: message,
          date: new Date().toLocaleString(locale)
        };
        state.addHistory(history);

        this.showWarning(message, 900000);

        await this.countJobs();

        return;
      }

      if (data.status === 'success') {

        if (data.type === 'authenticate') {
          const auth = {
            type: "authenticate",
            token: this.token
          };

          // Send authentication
          window.$websocket.send(JSON.stringify(auth));
        }
        if (data.type === 'authenticated') {
          // Set connected
          state.setConnected();
          this.connected = true;

          // Set session token
          state.setSessionToken(data.session_token);
          this.sessionToken = data.session_token;

          // Set Printer ID
          const printerId = await state.getPrinterId();

          // Set Printer ID on WS Server
          const printer = {
            type: "set_printer",
            printer_id: printerId,
            session_token: data.session_token
          };

          // Send request
          window.$websocket.send(JSON.stringify(printer));

          // Reset reconnect interval
          clearInterval(this.reconnectInterval);

          // Clear all messages
          window.$message.destroyAll();

          if (process.env.NODE_ENV === 'production') {
            console.clear();
          }
        }
        if (data.type === 'job_accepted') {
          const id = data.production_partner_job_id;

          // Get job details and update status and accepted
          let jobDetails = await state.getPrintJob(`job_${id}`);
          jobDetails.accepted = true;
          jobDetails.status = 'Downloading';
          await state.addUpdatePrintJob(`job_${id}`, jobDetails);

          window.$newJobs.updated = true;

          // Update history
          const locale = await state.getLocale();
          const message = `The Job with ID: ${id} has been successfully accepted.`;
          const history = {
            type: 'Job Accepted',
            job: id,
            message: message,
            date: new Date().toLocaleString(locale)
          };
          state.addHistory(history);

          this.showSuccess(message, 6000);

          this.downloadJobFiles(jobDetails);
        }
        if (data.type === 'status_updated') {
          window.$newJobs.updated = true;
        }
        if (data.type === 'problem_reported') {
          const id = data.production_partner_job_id;

          // Update job messages
          let jobDetails = await state.getPrintJob(`job_${id}`);
          jobDetails.status = 'Issue';
          jobDetails.messages.push({
            type: 'received',
            title: 'Issue Report Received',
            content: 'The Merchr platform has received the reported issue.',
          });
          await state.addUpdatePrintJob(`job_${id}`, jobDetails);

          window.$newJobs.updated = true;

          // Update history
          const locale = await state.getLocale();
          const message = `The issue for job with ID: ${id} has been successfully reported.`;
          const history = {
            type: 'Issue Reported',
            job: id,
            message: message,
            date: new Date().toLocaleString(locale)
          };
          state.addHistory(history);

          this.showSuccess(message, 6000);
        }
        
      } else {
        this.showError(`Header Error: ${data.type} Message: ${data.message}`, 8000); 
      }
    },
    async getToken() {
      this.token = await state.getToken();
      return this.token;
    },
    async checkWebsocketConnection() {
      await this.getToken();
      const locale = await state.getLocale();

      if (window.$websocket === null 
        && typeof this.token !== "undefined" 
        && this.token !== null
        && this.token !== ''
      ) {
        this.connectToWsServer()
          .then(ws => {
            // Set message listener
            ws.onmessage = (event) => {
              this.onMessage(event);
            };

            /// Set close listener
            ws.onclose = (event) => {
              this.onClose(event);
            };
            
            this.failedConnect = false;
            this.disconnected = false;

            const history = {
              type: 'Successfully Connected to Server',
              job: 0,
              message: 'The Printer App has successfully connected to the server.',
              date: new Date().toLocaleString(locale)
            };
            state.addHistory(history);
          })
          .catch(error => {
            if (this.failedConnect === false && this.disconnected === false) {
              this.failedConnect = true;
              this.showError(`Unable to connect to the Merchr Printer Web Socket Server! Error Received: ${error}`, 300000);
            }
            
            // Update history
            const history = {
              type: 'Unable to Connect to Server',
              job: 0,
              message: `The Printer App was unable to connect to the Merchr Server, retry will commence in 60 seconds. Error Message: ${error}`,
              date: new Date().toLocaleString(locale)
            };
            state.addHistory(history);

            this.reconnectInterval = setInterval( () => {
              this.reconnect();
            }, 60000);
          });
      }
    },
  },
  async beforeMount() {
    const self = this;
    const jobCount = window.$newJobs.count;
    const downloadId = window.$newJobs.downloadId;

    window.$newJobsHeader = new Proxy(
      {
        count: jobCount,
        downloadId: downloadId,
      },
      {
        set: function (target, key, value) {
          target[key] = value;

          if (key === 'count') {
            self.newJobCount = value;
          }
          
          if (key === 'downloadId' && value !== 0) {
            self.downloadJobFiles();
          }

          return true;
        },
      }
    );
    
    // Check for readwrite file system permissions
    await this.checkFilePermissions();
  },
  async mounted() {
    this.connected = await state.getConnected();
    if (!this.connected) {
      this.checkWebsocketConnection();
    }
    this.countJobs();
  },
}