import { reactive, ref } from 'vue';
import state from '@/state';

export default {
  name: 'HistoryData',
  setup() {
    const paginationReactive = reactive({
      page: 1,
      pageSize: 24,
      showSizePicker: true,
      pageSizes: [12, 24, 36, 48, 96, 120, 240, 480, 960, 1920],
      onChange: (page) => {
        paginationReactive.page = page
      },
      onUpdatePageSize: (pageSize) => {
        paginationReactive.pageSize = pageSize
        paginationReactive.page = 1
      }
    });

    const historyTable = ref();

    const downloadCsv = async function() {
      historyTable.value?.downloadCsv({ fileName: "history-logs" });
      
      const locale = await state.getLocale();
      const history = {
        type: 'Printer App History Exported',
        job: 0,
        message: 'The printer app history has been successfully exported.',
        date: new Date().toLocaleString(locale)
      };
      state.addHistory(history);
    };

    return {
      pagination: paginationReactive,
      historyTable,
      downloadCsv
    }
  },
  data: () => {
    return {
      printerName: null,
      history: ref([]),
      columns: [],
    }
  },
  computed: {
    headerText() {
      return 'History - ' + this.printerName;
    },
  },
  methods: {
    createColumns() {
      return [
        {
          title: 'Date/Time',
          key: 'date',
          width: 110,
        },
        {
          title: 'Job ID',
          key: 'job'
        },
        {
          title: 'Type',
          key: 'type'
        },
        {
          title: 'Details',
          key: 'details'
        }
      ];
    },
    async processRecords() {
      const records = await state.getHistory();
      let rows = [];

      records.forEach( function(record) { 
        rows.push(
          {
            date: record.date.replace(',', ''),
            job: record.job,
            type: record.type,
            details: record.message
          }
        );
      });

      this.history = rows.reverse();
    },
  },
  async beforeMount() {
    this.columns = await this.createColumns();
    this.printerName = await state.getPrinterName();
  },
  async mounted() {
    this.processRecords();
  },
}